require('./header.js');
require('./social-buttons.js');

require('./pages.js');

var $ = require('jquery');

$(function(){
	$('#view-switcher .switch')
		.on('click', function(){
			$(this).siblings().each(function(){
				$(this).removeClass('active');
				$('html').removeClass($(this).data('class'));
			})
			
			$(this).addClass('active');
			
			$('html').addClass($(this).data('class'));
		})
})