var $ = require('jquery');

$(function(){
	$('.share-button')
		.on('click', function(ev){
			ev.preventDefault();
			
			var href = $(this).attr('href');
			
			window.open(href, 'sharer', "toolbar=0,status=0,width=640,height=480");
		})
})